import { Fragment, useState, ReactElement } from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { getCurrencyView } from '../../utils/format';
import { Payment, PaymentDetails } from './Components/PaymentDetails';
import MandateRecipient from './Components/Recipient';
import SenderDetailsModal from './Components/Sender';
import { Dialog } from './MandatesTable';

export const PaymentsTable = ({ customerAppId, items }: { customerAppId: string; items: Payment[] }): ReactElement => {
  const [open, setOpen] = useState<string>('');
  const [dialog, setDialog] = useState<Dialog>(Dialog.Any);
  const handleClose = () => {
    handleMenuClose();
  };

  const setView = (paymentId: string, dialog: Dialog) => {
    setOpen(paymentId);
    setDialog(dialog);
  };

  const handleMenuClose = () => {
    setOpen('');
    setDialog(Dialog.Any);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="payments table">
        <TableHead>
          <TableRow sx={{ '& th': { fontWeight: 600 } }}>
            <TableCell align="center">Updated At (UTC)</TableCell>
            <TableCell align="center">Payment ID</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Details</TableCell>
            <TableCell align="center">Recipient</TableCell>
            <TableCell align="center">Sender</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: Payment) => (
            <Fragment key={item.payment_id}>
              <PaymentDetails
                payment={item}
                open={item.payment_id === open && dialog === Dialog.Details}
                handleClose={handleClose}
              />
              <MandateRecipient
                title="Payment Recipient"
                recipient={{
                  name: item.recipient.name,
                  accountId: item.recipient.recipient_account_id,
                  customerAppId: customerAppId,
                }}
                open={item.payment_id === open && dialog === Dialog.Recipient}
                handleClose={handleClose}
              />
              <SenderDetailsModal
                title="Payment Sender"
                sender={{
                  name: item.sender.name,
                  fvUserId: item.sender.id,
                  externalUserId: item.sender.external_user_id,
                  senderType: item.sender.sender_type,
                  senderInstitution: item.sender.sender_account.institution_id,
                }}
                open={item.payment_id === open && dialog === Dialog.Sender}
                handleClose={handleClose}
              />
              <TableRow key={item.payment_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">{item.updated_at}</TableCell>
                <TableCell align="center">{item.payment_id}</TableCell>
                <TableCell align="center">{item.status}</TableCell>
                <TableCell align="center">{item.type}</TableCell>
                <TableCell align="center">{getCurrencyView(item.amount)}</TableCell>
                <TableCell align="center">{item.currency}</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      setView(item.payment_id, Dialog.Details);
                    }}
                  >
                    {item.payment_details.description}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      setView(item.payment_id, Dialog.Recipient);
                    }}
                  >
                    {item.recipient.name}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      setView(item.payment_id, Dialog.Sender);
                    }}
                  >
                    {item.sender.name}
                  </Button>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
