export const mandateStatuses = [
  'AUTHORIZATION_REQUIRED',
  'AUTHORIZING',
  'PROCESSING',
  'SUBMITTED',
  'SUCCEEDED',
  'FAILED',
];

export const cardStatuses = ['PROCESSING', 'SUCCEEDED', 'FAILED', 'CANCELLED'];

export const paymentMethodStatuses = Array.from(new Set([...mandateStatuses, ...cardStatuses]));
