import { Theme } from '@emotion/react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TeamSettingsIcon from '@mui/icons-material/Group';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import { SxProps } from '@mui/material';

import Routes from '../../lib/routes';

export const DesktopDrawerWidth = 'max(260px, 15%)';
export const MobileDrawerWidth = '240';
export const MobileDrawerWidthPercentage = '20%';

const LogoWidth = 200;
export const styles: Record<string, SxProps<Theme>> = {
  logo: {
    width: LogoWidth,
  },
};

export const getEnv = (hostname: string): 'dev2' | 'prod' => {
  if (hostname.includes('dev2') === true) {
    return 'dev2';
  }

  return 'prod';
};

export const getHeaderRoutes = (env: string, customerAppId: string) => [
  {
    route: Routes.Overview,
    buttonText: 'Overview',
    Icon: <HomeOutlinedIcon />,
  },
  {
    route: `https://developer.${env}.finverse.net/auth/demoapp?redirect_uri=https://demo-app.${env}.finverse.net/get-token`,
    buttonText: 'Demo App',
    external: true,
    Icon: <OndemandVideoOutlinedIcon />,
  },
  {
    route: Routes.Docs,
    buttonText: 'Documentation',
    external: true,
    Icon: <DescriptionOutlinedIcon />,
  },

  {
    route: Routes.Application.Settings,
    buttonText: 'API Settings',
    Icon: <MemoryOutlinedIcon />,
  },
  {
    route: Routes.Customizations,
    buttonText: 'Customizations',
    Icon: <DashboardCustomizeIcon />,
  },
  {
    buttonText: 'Payments',
    Icon: <CreditCardIcon />,
    nestedRoutes: [
      { buttonText: 'Mandates', route: Routes.Reports.Mandates },
      { buttonText: 'Payment Methods', route: Routes.Reports.PaymentMethods },
      { buttonText: 'Payments', route: Routes.Reports.Payments },
      { buttonText: 'Payouts', route: Routes.Reports.Payouts },
      { buttonText: 'Statement', route: Routes.Reports.Statement },
      { buttonText: 'Xero Integration', route: Routes.Integrations.Xero },
    ],
  },
  {
    buttonText: 'Quickstart Links',
    Icon: <TouchAppOutlinedIcon />,
    nestedRoutes: [
      { buttonText: 'Guide', route: Routes.QuickStart.Guide },
      { buttonText: 'Settings', route: Routes.QuickStart.Settings },
      { buttonText: 'Linked Users', route: `${Routes.QuickStart.LinkedUsers}/${customerAppId}` },
    ],
  },
  // ! Keep this button commented until xero feature is ready
  // {
  //   buttonText: 'Integrations',
  //   Icon: <ExtensionIcon />,
  //   route: Routes.Integrations.Base,
  // },
  {
    buttonText: 'Team Settings',
    Icon: <TeamSettingsIcon />,
    nestedRoutes: [
      { buttonText: 'Members', route: Routes.Teams.Base },
      { buttonText: 'Settings', route: Routes.Teams.Settings },
    ],
  },
];
