import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import RowIfNotEmpty from './Row';
import DialogWrapper from './Wrapper';

type MandateDetailsProps = {
  title: string;
  open: boolean;
  handleClose: () => void;
  details: MandateDetailsData;
};

type MandateDetailsData = {
  currency: string;
  description: string;
  startDate: string;
  endDate: string;
  maxTotalAmount: string;
  maxTxnAmount: string;
  maxTxnCount?: number;
  period: string;
  paymentMethodId: string;
  mandateId: string;
  bankReference?: string;
  recipientName: string;
  recipientAccountId: string;
  customerAppId: string;
};

export function MandateDetails({ title, open, handleClose, details }: MandateDetailsProps) {
  return (
    <DialogWrapper id="" onClose={handleClose} aria-labelledby="customized-dialog-title" title={title} open={open}>
      <DialogContent dividers>
        <Table>
          <TableBody>
            {RowIfNotEmpty('Bank Reference', details.bankReference)}
            <TableRow>
              <TableCell>Payment Method ID</TableCell>
              <TableCell align="right">{details.paymentMethodId}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Mandate ID', details.mandateId)}
            <TableRow>
              <TableCell>Recipient Name</TableCell>
              <TableCell align="right">{details.recipientName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient Account ID</TableCell>
              <TableCell align="right">{details.recipientAccountId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Customer App ID</TableCell>
              <TableCell align="right">{details.customerAppId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell align="right">{details.currency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">{details.description}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Start Date', details.startDate)}
            {RowIfNotEmpty('End Date', details.endDate)}
            {RowIfNotEmpty('Maximum Total Amount', details.maxTotalAmount)}
            {RowIfNotEmpty('Maximum Transaction Count', details.maxTxnCount?.toString())}
            {RowIfNotEmpty('Maximum Transaction Amount', details.maxTxnAmount)}
            {RowIfNotEmpty('Period', details.period)}
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}

type CardDetailsData = {
  cardBrand: string;
  cardLast4: string;
  cardExpiryMonth?: string;
  cardExpiryYear?: string;
  paymentMethodId: string;
  customerAppId: string;
  recipientName?: string;
  recipientAccountId?: string;
};

type CardDetailsProps = {
  title: string;
  open: boolean;
  handleClose: () => void;
  details: CardDetailsData;
};

export function CardDetails({ title, open, handleClose, details }: CardDetailsProps) {
  const cardExpiry =
    details.cardExpiryMonth && details.cardExpiryYear ? `${details.cardExpiryMonth}/${details.cardExpiryYear}` : '';
  return (
    <DialogWrapper
      id="card-details-modal"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      title={title}
      open={open}
    >
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Brand</TableCell>
              <TableCell align="right">{details.cardBrand}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last 4</TableCell>
              <TableCell align="right">{details.cardLast4}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Expiry', cardExpiry)}
            {RowIfNotEmpty('Payment Method ID', details.paymentMethodId)}
            {RowIfNotEmpty('Customer App ID', details.customerAppId)}
            {RowIfNotEmpty('Recipient Name', details.recipientName)}
            {RowIfNotEmpty('Recipient Account ID', details.recipientAccountId)}
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}
