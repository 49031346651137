import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import DialogWrapper from './Wrapper';

type SenderDetails = {
  name: string;
  fvUserId: string;
  externalUserId: string;
  senderInstitution?: string; // we may add this in future
  senderType: string;
};

export default function SenderDetailsModal({
  title,
  open,
  handleClose,
  sender,
}: {
  title: string;
  open: boolean;
  handleClose: () => void;
  sender: SenderDetails;
}) {
  return (
    <DialogWrapper
      id="sender-details-modal"
      onClose={handleClose}
      aria-labelledby="mandate-recipient"
      title={title}
      open={open}
    >
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Sender Name</TableCell>
              <TableCell align="right">{sender.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Finverse User ID</TableCell>
              <TableCell align="right">{sender.fvUserId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>External User ID</TableCell>
              <TableCell align="right">{sender.externalUserId}</TableCell>
            </TableRow>
            {sender.senderInstitution !== undefined && (
              <TableRow>
                <TableCell>Sender Institution</TableCell>
                <TableCell align="right">{sender.senderInstitution}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Sender Type</TableCell>
              <TableCell align="right">{sender.senderType}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}
