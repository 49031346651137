import { useEffect, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { redirectToAuth0 } from '../lib/auth0';
import Routes from '../lib/routes';

export default function Verified(): JSX.Element {
  const { app, setApp } = useContext(AppContext);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const success = query.get('success');
  const token = query.get('token');

  useEffect(() => {
    if (success === 'true') {
      redirectToAuth0(app.env);
    } else {
      const currEnv = app.env;
      const tokens = app.tokens;
      if (token !== null) {
        tokens.set(currEnv, token);
        setApp({ ...app, tokens });
      }
      history.push(`${Routes.Error}/CANNOT_VERIFY_EMAIL`);
    }
  }, [app, history, token, setApp, success]);

  return <div className="pageContainer"></div>;
}
