import { Fragment, useState, ReactElement } from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

import { getCurrencyView } from '../../utils/format';
import { MandateDetails } from './Components/Details';
import SenderDetailsModal from './Components/Sender';

type TransactionLimit = {
  max_period_amount: number;
  max_period_count: number;
  max_transaction_amount: number;
  period: string;
};

export type Recipient = {
  name: string;
  recipient_account_id: string;
};

export type Sender = {
  id: string;
  name: string;
  sender_type: string;
  external_user_id: string;
  sender_account: {
    institution_id?: string;
  };
};

export type SenderAccount = {
  user_id: string;
};

type MandateDetailsData = {
  currency: string;
  description: string;
  start_date: string;
  end_date: string;
  mandate_bank_reference?: string;
  transaction_limits: TransactionLimit;
};

export type Mandate = {
  mandate_id: string;
  payment_method_id: string;
  updated_at: string;
  status: string;
  mandate_details: MandateDetailsData;
  recipient: Recipient;
  sender: Sender;
  sender_account: SenderAccount;
};

export type PaymentUser = {
  autopay_consent: boolean;
};

export type MandateWithJoinedData = {
  mandate: Mandate;
  payment_user: PaymentUser;
};

export enum Dialog {
  Any = '',
  Details = 'DETAILS',
  Recipient = 'RECIPIENT',
  Sender = 'SENDER',
}

export const MandatesTable = ({
  customerAppId,
  items,
}: {
  customerAppId: string;
  items: MandateWithJoinedData[];
}): ReactElement => {
  const [open, setOpen] = useState<string>('');
  const [dialog, setDialog] = useState<Dialog>(Dialog.Any);
  const handleClose = () => {
    handleMenuClose();
  };

  const setView = (mandateId: string, dialog: Dialog) => {
    setOpen(mandateId);
    setDialog(dialog);
  };

  const handleMenuClose = () => {
    setOpen('');
    setDialog(Dialog.Any);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="mandates table">
        <TableHead>
          <TableRow sx={{ '& th': { fontWeight: 600 } }}>
            <TableCell align="center">Updated At (UTC)</TableCell>
            <TableCell align="center">Mandate ID</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Details</TableCell>
            <TableCell align="center">Sender</TableCell>
            <TableCell align="center">Autopay Consent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: MandateWithJoinedData) => (
            <Fragment key={item.mandate.mandate_id}>
              <MandateDetails
                title="Mandate Details"
                details={{
                  currency: item.mandate.mandate_details.currency,
                  bankReference: item.mandate.mandate_details.mandate_bank_reference,
                  paymentMethodId: item.mandate.payment_method_id,
                  mandateId: item.mandate.mandate_id,
                  description: item.mandate.mandate_details.description,
                  startDate: item.mandate.mandate_details.start_date,
                  endDate: item.mandate.mandate_details.end_date,
                  maxTotalAmount: getCurrencyView(item.mandate.mandate_details.transaction_limits?.max_period_amount),
                  maxTxnAmount: getCurrencyView(
                    item.mandate.mandate_details.transaction_limits?.max_transaction_amount,
                  ),
                  maxTxnCount: item.mandate.mandate_details.transaction_limits?.max_period_count,
                  period: item.mandate.mandate_details.transaction_limits?.period,
                  recipientName: item.mandate.recipient.name,
                  recipientAccountId: item.mandate.recipient.recipient_account_id,
                  customerAppId: customerAppId,
                }}
                open={item.mandate.mandate_id === open && dialog === Dialog.Details}
                handleClose={handleClose}
              />
              <SenderDetailsModal
                title="Mandate Sender"
                sender={{
                  name: item.mandate.sender.name,
                  externalUserId: item.mandate.sender.external_user_id,
                  fvUserId: item.mandate.sender_account?.user_id,
                  senderType: item.mandate.sender.sender_type,
                  senderInstitution: item.mandate.sender.sender_account.institution_id,
                }}
                open={item.mandate.mandate_id === open && dialog === Dialog.Sender}
                handleClose={handleClose}
              />
              <TableRow key={item.mandate.mandate_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">{item.mandate.updated_at}</TableCell>
                <TableCell align="center">
                  <Button>
                    <Link
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      to={`/reports/payments?mandate_id=${item.mandate.mandate_id}`}
                    >
                      {item.mandate.mandate_id}
                    </Link>
                  </Button>
                </TableCell>
                <TableCell align="center">{item.mandate.status}</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      setView(item.mandate.mandate_id, Dialog.Details);
                    }}
                  >
                    {item.mandate.mandate_details.description || '...'}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      setView(item.mandate.mandate_id, Dialog.Sender);
                    }}
                  >
                    {item.mandate.sender.name || '...'}
                  </Button>
                </TableCell>
                <TableCell align="center">{item.payment_user.autopay_consent ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
