import { useEffect, useState, ReactElement } from 'react';

import { outlinedInputClasses, TextFieldProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import DateRangePicker, { DateRangeValues, NullableDate } from '../../components/DateRangePicker';
import useQuery from '../../hooks/useQuery';
import { getErrorMessage } from '../../lib/error';
import { mandateStatuses } from '../../lib/status';
import { ListMandates } from '../../lib/useDeveloperApi';
import { getNumericVal } from '../../utils/number';
import { filterInstitutions, getInstitutions } from './institutions';
import { MandatesTable, MandateWithJoinedData } from './MandatesTable';

interface Filter {
  senderType: string;
  userId: string;
  institutionId: string;
  offset: number;
}

const getStyles = (name: string, statuses: string[], theme: Theme) => {
  return {
    fontWeight: statuses.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

const getPre = (query: string) => (query === '' ? '?' : '&');

const getParams = (values: Filter, dateRangeValue: DateRangeValues, status: string[]): string => {
  let params = '';
  if (dateRangeValue[0] !== null) {
    params += `${getPre(params)}date_from=${dateRangeValue[0].toISOString().slice(0, 10)}`;
  }
  if (dateRangeValue[1] !== null) {
    params += `${getPre(params)}date_to=${dateRangeValue[1].toISOString().slice(0, 10)}`;
  }
  if (values.senderType !== '') {
    params += `${getPre(params)}sender_type=${values.senderType}`;
  }
  if (values.userId !== '') {
    params += `${getPre(params)}user_id=${values.userId}`;
  }
  if (values.institutionId !== '') {
    params += `${getPre(params)}institution_id=${values.institutionId}`;
  }
  if (status.length > 0) {
    params += `${getPre(params)}status=${status.join(',')}`;
  }
  return params;
};

const ViewMandates = ({ customerAppId }: { customerAppId: string }): ReactElement => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [mandates, setMandates] = useState<MandateWithJoinedData[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const history = useHistory();
  // change the following constant to change the page size
  const pageSize = 100;

  let initialStatus: string[] = [];
  const query = useQuery();
  const statusQuery = query.get('status');
  if (statusQuery !== null) {
    initialStatus = statusQuery.split(',');
  }

  const { institutionIds, institutionIdToName } = getInstitutions();

  // Date range state
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValues>([null, null]);

  const [status, setStatus] = useState<string[]>(initialStatus);
  const { data, error } = ListMandates(customerAppId, {
    statuses: initialStatus,
    from: query.get('date_from') ?? undefined,
    to: query.get('date_to') ?? undefined,
    senderType: query.get('sender_type') ?? undefined,
    userId: query.get('user_id') ?? undefined,
    institutionId: query.get('institution_id') ?? undefined,
    limit: pageSize,
    offset: getNumericVal(query.get('offset'), 0),
  });

  const [values, setValues] = useState<Filter>({
    senderType: query.get('sender_type') ?? '',
    userId: query.get('user_id') ?? '',
    institutionId: query.get('institution_id') ?? '',
    offset: getNumericVal(query.get('offset'), 0),
  });

  // for handling changes to data
  useEffect(() => {
    setMandates(data?.mandates ?? []);
    setTotal(data?.total_mandates ?? null);
    if (data) {
      enqueueSnackbar('Loaded mandates', { variant: 'info', autoHideDuration: 1000 });
    }
  }, [data, enqueueSnackbar]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  // update the view when query params changes
  useEffect(() => {
    const dateFrom = query.get('date_from');
    const dateTo = query.get('date_to');
    if (dateFrom !== null || dateTo !== null) {
      setDateRangeValue((prevState) => {
        const df = dateFrom !== null ? new Date(dateFrom) : prevState[0];
        const dt = dateTo !== null ? new Date(dateTo) : prevState[1];
        return [df, dt];
      });
    }

    setValues({
      senderType: query.get('sender_type') ?? '',
      userId: query.get('user_id') ?? '',
      institutionId: query.get('institution_id') ?? '',
      offset: getNumericVal(query.get('offset'), 0),
    });
  }, [query]);

  const handleChange = (prop: keyof Filter) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSelectChange = (prop: keyof Filter) => (event: SelectChangeEvent) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleStatusChange = (event: SelectChangeEvent<typeof mandateStatuses>) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value);
  };

  const goPrev = () => {
    let params = getParams(values, dateRangeValue, status);
    params += `${getPre(params)}offset=${values.offset - pageSize}`;

    history.push({
      search: params,
    });
  };

  const goNext = () => {
    let params = getParams(values, dateRangeValue, status);
    params += `${getPre(params)}offset=${values.offset + pageSize}`;

    history.push({
      search: params,
    });
  };

  const loadView = () => {
    history.push({
      search: getParams(values, dateRangeValue, status),
    });
  };

  // this function will set the current date time so the UTC value for date string will be the same as the locale time
  // we do this by adding the UTC offset to the date (local), eg 8am in HKT -> 12am in UTC same date.
  // by default the DatePicker component returns the date time as midnight which when converted to UTC will return yesterday's date
  const setDateWithOffset = (newValue: Date) => {
    newValue.getTimezoneOffset(); // get the UTC offset
    newValue.setHours(0); // set the hour to 0 so adding offset will always be the same
    newValue.setMinutes(-newValue.getTimezoneOffset()); // add the offset so we get the local time with UTC offset
  };

  const searchBar = () => {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={2}>
        <DateRangePicker
          values={dateRangeValue}
          views={['day']}
          inputFormat="yyyy-MM-dd"
          onStartDateChange={(newValue: NullableDate) => {
            if (newValue !== null) {
              setDateWithOffset(newValue);
            }
            setDateRangeValue([newValue, dateRangeValue[1]]);
          }}
          onEndDateChange={(newValue: NullableDate) => {
            if (newValue !== null) {
              setDateWithOffset(newValue);
            }
            setDateRangeValue([dateRangeValue[0], newValue]);
          }}
          renderInput={(params: TextFieldProps) => (
            <Box sx={{ width: 200 }}>
              <TextField
                fullWidth
                {...params}
                sx={{
                  [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
                }}
              />
            </Box>
          )}
        />
        <FormControl fullWidth>
          <InputLabel id="select-status-label">Status</InputLabel>
          <Select
            labelId="select-status-label"
            id="select-status"
            multiple
            value={status}
            fullWidth
            onChange={handleStatusChange}
            renderValue={(selected) => selected.join(', ')}
            label="Status"
          >
            {mandateStatuses.map((s) => (
              <MenuItem sx={{ fontSize: '0.8em' }} key={s} value={s} style={getStyles(s, mandateStatuses, theme)}>
                <Checkbox checked={status.includes(s)} />
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="sender-type-select-label">Sender Type</InputLabel>
          <Select
            labelId="sender-type-select-label"
            id="sender-type-select"
            value={values.senderType}
            label="Sender Type"
            onChange={handleSelectChange('senderType')}
          >
            <MenuItem value="" sx={{ fontSize: '0.8em' }}>
              <em>Any</em>
            </MenuItem>
            <MenuItem value="INDIVIDUAL" sx={{ fontSize: '0.8em' }}>
              Individual
            </MenuItem>
            <MenuItem value="BUSINESS" sx={{ fontSize: '0.8em' }}>
              Business
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="user-id"
          label="User ID"
          variant="outlined"
          value={values.userId}
          onChange={handleChange('userId')}
          fullWidth
        />
        <Autocomplete
          id="institution-id"
          value={values.institutionId}
          options={institutionIds}
          fullWidth
          clearOnBlur
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setValues({
                ...values,
                institutionId: newValue,
              });
            } else {
              setValues({
                ...values,
                institutionId: '',
              });
            }
          }}
          getOptionLabel={(option: string) => institutionIdToName[option] ?? option}
          filterOptions={filterInstitutions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sender Institution"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Button fullWidth variant="contained" onClick={loadView}>
          View
        </Button>
      </Stack>
    );
  };

  const titleBar = () => (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        marginBottom: '1em',
        marginTop: '0.5em',
      }}
    >
      <Grid item>
        <Typography variant="h4" component="h4">
          Mandates
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">
          Showing {Math.min(total as number, values.offset + 1)} - {Math.min(values.offset + pageSize, total as number)}{' '}
          of {total}
        </Typography>
      </Grid>
    </Grid>
  );

  const pagination = () => (
    <ButtonGroup
      disableElevation
      size="small"
      aria-label="Pagination"
      variant="contained"
      sx={{ width: '250px', marginTop: '1em' }}
    >
      <Button fullWidth disabled={values.offset === 0} onClick={goPrev}>
        Previous
      </Button>
      <Button
        fullWidth
        disabled={Math.min(values.offset + pageSize, total as number) >= Number(total)}
        onClick={goNext}
      >
        Next
      </Button>
    </ButtonGroup>
  );

  return (
    <>
      {searchBar()}
      {titleBar()}
      <MandatesTable customerAppId={customerAppId} items={mandates} />
      {pagination()}
    </>
  );
};

export default ViewMandates;
