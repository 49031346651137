import { useCallback, useEffect, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import IntegrationCard, { ConnectionStatus } from '../../../components/Integrations/IntegrationCard';
import { LoadingButton, LoadingCard } from '../../../components/LoadingComponents';
import useApp from '../../../hooks/useApp';
import { getErrorMessage } from '../../../lib/error';
import Routes from '../../../lib/routes';
import {
  getSDKClient,
  XeroConnection,
  XeroAccount,
  ErrBodyModelV2,
  FinverseSDKClient,
  Institution,
  XeroBankDetails,
} from '../../../lib/sdk';

type XeroUserActionReqProps = {
  customerAppId?: string;
  xeroConnectionSessionId: string;
  teamId: string;
  isLive: boolean;
};

const getPayoutInstitutions = async (sdk: FinverseSDKClient) => {
  const institutions = await sdk.getInstitutions();
  return institutions.filter((i) => i.products_supported.includes('PAYOUTS'));
};

const ALLOWED_CURRENCIES = ['HKD', 'CNY'];

export default function XeroUserActionReq(props: XeroUserActionReqProps) {
  const { app } = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const getInstitutions = useCallback(async () => {
    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';
    const sdk = getSDKClient(env, currentToken);
    const i = await getPayoutInstitutions(sdk);
    setInstitutions(i);
  }, [app]);

  const [organizations, setOrganizations] = useState<XeroConnection[]>([]);
  const [organizationId, setOrganizationId] = useState('');

  const [expenseAccs, setExpenseAccs] = useState<XeroAccount[]>([]);
  const [bankAccs, setBankAccs] = useState<XeroAccount[]>([]);
  const [expenseAccountId, setExpenseAccountId] = useState('');
  const [bankAccountId, setBankAccountId] = useState('');

  // used to indicate whether we are getting the options for this data
  const [gettingOrgs, setGettingOrgs] = useState(false);
  const [gettingAccounts, setGettingAccounts] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getOrganizations = useCallback(async () => {
    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';
    const sdk = getSDKClient(env, currentToken);
    try {
      // always reset value to empty
      setOrganizationId('');
      const responseData = await sdk.getXeroAccountConnections(props.xeroConnectionSessionId);
      setOrganizations(responseData);
      if (responseData.length === 1) {
        // if there is only one organization, we prepopulate the select value
        setOrganizationId(responseData[0].tenant_id);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
    } finally {
      setGettingOrgs(false);
    }
  }, [app, props.xeroConnectionSessionId, enqueueSnackbar]);

  const getAccounts = useCallback(
    async (tenantId: string) => {
      const { tokens, env } = app;
      const currentToken = tokens.get(env) ?? '';
      const sdk = getSDKClient(env, currentToken);
      try {
        const [expenseAccs, bankAccs] = await Promise.all([
          sdk.getXeroConnectionExpenseAccounts(props.xeroConnectionSessionId, tenantId),
          sdk.getXeroConnectionBankAccounts(props.xeroConnectionSessionId, tenantId),
        ]);

        const sortedExpenseAccs = expenseAccs.sort((a, b) => {
          // even though account code is a number stored as string, they are all 3 digit, so we can compare as if they were numbers
          if (a.account_code < b.account_code) return -1;
          return 1;
        });
        const sortedBankAccs = bankAccs.sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        });

        setExpenseAccs(sortedExpenseAccs);
        setBankAccs(sortedBankAccs);
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      } finally {
        setGettingAccounts(false);
      }
    },
    [app, props.xeroConnectionSessionId, enqueueSnackbar],
  );

  // this function should never return (i.e. we are always redirecting the user away in all cases)
  // if this function needs to return and keep the user on the same page, remember to set setIsSubmitting to false
  const submitSelections = useCallback(
    async (tenantId: string, expenseAccountId: string, bankAccountId: string, bankDetails: XeroBankDetails) => {
      const { tokens, env } = app;
      const currentToken = tokens.get(env) ?? '';
      const sdk = getSDKClient(env, currentToken);

      try {
        enqueueSnackbar('Submitting selections', { variant: 'info', autoHideDuration: 1000 });
        setIsSubmitting(true);

        await sdk.confirmXeroSelections(
          props.teamId,
          props.xeroConnectionSessionId,
          tenantId,
          expenseAccountId,
          bankAccountId,
          bankDetails,
          props.customerAppId,
        );
        // if this succeeds, then we should "refresh" this page (aka remove search params)
        window.location.href = Routes.Integrations.Xero;
      } catch (err) {
        // For all these xero connection errors (at final step)
        // We will take dev back to the "Connect Xero" screen, with the static
        // "Warning" style error dialog
        // Only unwrap errors from 400, for the rest we will rely on generic error.
        if (axios.isAxiosError(err) && err.response !== undefined && err.response.status === 400) {
          const errBody: ErrBodyModelV2 = err.response.data;
          const errorRoute = `${Routes.Integrations.Xero}?error=true&message=${errBody.error.message}&details=${errBody.error.details}`;
          history.replace(errorRoute);
          return;
        }

        // Return to error screen, expecting default error
        const errorRoute = `${Routes.Integrations.Xero}?error=true`;
        history.replace(errorRoute);
        return;
      }
    },
    [app, enqueueSnackbar, props.xeroConnectionSessionId, history, props.customerAppId, props.teamId],
  );

  // on component load, we should get the orgs
  useEffect(() => {
    setGettingOrgs(true);
    getOrganizations();
    getInstitutions();
  }, [getOrganizations, getInstitutions]);

  // every time orgId changes, we reload accounts
  useEffect(() => {
    setExpenseAccountId('');
    setBankAccountId('');
    setExpenseAccs([]);
    if (organizationId == '') return;
    setGettingAccounts(true);
    getAccounts(organizationId);

    // Find and set account holder name
    const org = organizations.find((o) => o.tenant_id === organizationId);

    if (org !== undefined) {
      setAccountHolderName(org.name);
    }
  }, [organizationId, organizations, getAccounts]);

  // The stuff for the bank acount details
  const [institutionId, setInstitutionId] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  // const [currencies, setCurrencies] = useState<string[]>([]);
  const [currency, setCurrency] = useState('');

  const isFormDisabled = (): boolean => {
    const requiredFields = [
      organizationId,
      expenseAccountId,
      bankAccountId,
      accountNumber,
      accountHolderName,
      institutionId,
      currency,
    ];

    for (const rf of requiredFields) {
      if (rf === '') {
        return true;
      }
    }

    // Make sure accountNumber regex matched as well
    const digitRegex = new RegExp(/^[0-9]+$/);
    if (digitRegex.test(accountNumber) === false) {
      return true;
    }

    return false;
  };

  // everytime bankAccountId changes, we need to load stuff for bank details section
  useEffect(() => {
    // Iterate bankAccs and find the one matching bankAccountId
    // That's the one we need to use to poplate stuffs
    const bankAcc = bankAccs.find((acc) => acc.account_id === bankAccountId);

    if (bankAcc !== undefined && bankAcc.bank_account_number !== undefined) {
      setAccountNumber(bankAcc.bank_account_number);
    }
  }, [bankAccs, bankAccountId]);

  useEffect(() => {
    const digitRegex = new RegExp(/^[0-9]+$/);
    if (digitRegex.test(accountNumber) === false) {
      setAccountNumberError('The account number must only be digits (0-9)');
    } else {
      setAccountNumberError('');
    }
  }, [accountNumber]);

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ textAlign: 'left' }}
        spacing={3}
      >
        <Grid container item direction="row" sx={{ marginBottom: 4 }}>
          <Grid item>
            <Typography variant="h2" component="h2" sx={{ fontWeight: 'bold' }}>
              Integrate with Xero
            </Typography>
          </Grid>
        </Grid>
        <Grid container item display="flex">
          <Alert sx={{ flex: 1 }} severity="warning" variant="standard">
            <AlertTitle sx={{ fontWeight: 'bold' }}>User Action Required</AlertTitle>
            Please provide additional data to complete the connection
          </Alert>
        </Grid>
        <Grid container item display="flex">
          <IntegrationCard
            logoSrc="/integrations/xero/xero_logo.svg"
            name="Xero"
            description="Automate payment collection and reconciliation by enabling customers to make instant bank payments from any Xero invoice"
            buttonText="User Action Required"
            integrationStatus={ConnectionStatus.USER_ACTION_REQUIRED}
            isLive={props.isLive}
          />
        </Grid>
        <Grid container item display="flex">
          <LoadingCard loading={gettingOrgs}>
            <Box>
              <Typography fontWeight="bold" gutterBottom>
                Select which organization you want Finverse to connect to
              </Typography>
              <Box sx={{ width: 300 }}>
                <FormControl sx={{ marginTop: 1 }} fullWidth>
                  <InputLabel>Organization</InputLabel>
                  <Select
                    label="Organization"
                    value={organizationId}
                    onChange={(e) => setOrganizationId(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Select Organization
                    </MenuItem>
                    {organizations.map((org) => (
                      <MenuItem value={org.tenant_id} key={org.tenant_id}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </LoadingCard>
        </Grid>
        {
          // only load this when organization has been chosen
          organizationId != '' && (
            <>
              <Grid container item display="flex">
                <LoadingCard loading={gettingAccounts}>
                  <Box>
                    <Typography fontWeight="bold" gutterBottom>
                      Select a Xero expense account to record fees charged by Finverse
                    </Typography>
                    <Box sx={{ width: 300 }}>
                      <FormControl sx={{ marginTop: 1 }} fullWidth>
                        <InputLabel>Account</InputLabel>
                        <Select
                          label="Expense Account"
                          value={expenseAccountId}
                          onChange={(e) => setExpenseAccountId(e.target.value)}
                        >
                          <MenuItem value="" disabled>
                            Select Account
                          </MenuItem>
                          {expenseAccs.map((acc) => (
                            <MenuItem value={acc.account_id} key={acc.account_id}>
                              {acc.account_code} - {acc.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </LoadingCard>
              </Grid>
              <Grid container item display="flex">
                <LoadingCard loading={gettingAccounts}>
                  <Box>
                    <Typography fontWeight="bold" gutterBottom>
                      Select a Xero bank account to record your payouts
                    </Typography>
                    <Typography variant="subtitle2">
                      If you don&apos;t find the right account here, you can create new bank accounts in Xero and try
                      connecting again.
                    </Typography>
                    <Box sx={{ width: 300 }}>
                      <FormControl sx={{ marginTop: 1 }} fullWidth>
                        <InputLabel>Xero bank account</InputLabel>
                        <Select
                          label="Xero bank account"
                          value={bankAccountId}
                          onChange={(e) => setBankAccountId(e.target.value)}
                        >
                          <MenuItem value="" disabled>
                            Select Xero bank account
                          </MenuItem>
                          {bankAccs.map((acc) => (
                            <MenuItem value={acc.account_id} key={acc.account_id}>
                              {acc.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </LoadingCard>
              </Grid>
            </>
          )
        }
        {
          // only load this when bank account is selected
          bankAccountId != '' && (
            <Grid container item display="flex">
              <LoadingCard loading={false}>
                <Box>
                  <Typography fontWeight="bold" gutterBottom>
                    Input your bank account details
                  </Typography>
                  <Typography variant="subtitle2">
                    This information is used by Finverse to payout funds to your company&apos;s bank account. Please
                    make sure the information is correct.
                  </Typography>
                </Box>

                <Box sx={{ marginTop: '20px', width: 300 }}>
                  <Autocomplete
                    id="institution-id"
                    options={institutions.map((i) => i.institution_id)}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setInstitutionId(newValue);
                      } else {
                        setInstitutionId('');
                      }
                    }}
                    getOptionLabel={(option: string) =>
                      institutions.find((i) => i.institution_id === option)?.institution_name || ''
                    }
                    renderInput={(params) => <TextField {...params} label="Institution name" />}
                  />
                </Box>
                <Box sx={{ marginTop: '20px', width: 300 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="name"
                      label="Account number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      fullWidth
                      error={accountNumberError !== ''}
                      helperText={accountNumberError}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ marginTop: '20px', width: 300 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="name"
                      label="Account holder name"
                      value={accountHolderName}
                      onChange={(e) => setAccountHolderName(e.target.value)}
                      fullWidth
                    />
                  </FormControl>
                </Box>
                <Box sx={{ marginTop: '20px', width: 300 }}>
                  <FormControl fullWidth>
                    <InputLabel id="acccount-currency">Account currency</InputLabel>
                    <Select
                      id="currency"
                      value={currency}
                      fullWidth
                      renderValue={(selected) => selected}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCurrency(val);
                        // if (typeof val === 'string') {
                        //   setCurrencies(val.split(', '));
                        // } else {
                        //   setCurrencies(val);
                        // }
                      }}
                      label="Account currency"
                      labelId="acccount-currency"
                    >
                      {ALLOWED_CURRENCIES.map((s) => (
                        <MenuItem sx={{ fontSize: '0.8em' }} key={s} value={s}>
                          {/* <Checkbox checked={currencies.includes(s)} /> */}
                          <Checkbox checked={s === currency} />
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </LoadingCard>
            </Grid>
          )
        }
        <Grid container item display="flex" justifyContent="center" alignItems="center">
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            size="large"
            disabled={isFormDisabled()}
            onClick={() =>
              submitSelections(organizationId, expenseAccountId, bankAccountId, {
                institution_id: institutionId,
                account_number: accountNumber,
                account_holder_name: accountHolderName,
                currency: currency,
              })
            }
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}
